
import React, {  useEffect, useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import '../Customcss/table.css'

import Puff from 'react-loading-icons/dist/esm/components/puff';
import { useStateContext } from '../Context/ContextProvider';
import { toast } from 'react-toastify';


function Product() {
 const{type,weburl}= useStateContext();
  const [isClick,setIsClick]=useState(false);
  const [isThisClick,setIsThisClick]=useState('');
    const [category,setCategory]=useState([]);
    const [distributer,setDistributer]=useState([]);
    const [defaultCategory,setDefaultCategory]=useState('');
    const [defaultStore,setDefaultStore]=useState('');
    const [defaultDistributer,setDefaultDistributer]=useState('');
    const [store,setStore]=useState([]);
    const [packsaleprice,setPackSalePrice]=useState('');
    const [packcostprice,setPackCostPrice]=useState('');
    const [unitsaleprice,setUnitSalePrice]=useState('');
    const [unitcostprice,setUnitCostPrice]=useState('');
    const [qty,setQty]=useState('');
    const [packsize,setPackSize]=useState('1');
    const [packqty,setPackQty]=useState('1');
    const [unitretailprice,setUnitRetailPrice]=useState('');
   
   
  
    const [totalPages, setTotalPages] = useState(1);
    const [products,setProducts]=useState([]);
const [searchQuery,setSearchQuery]=useState('');
const [productid,setProductId]=useState('');
const [image, setImage] = useState(null);
const [imagePreview, setImagePreview] = useState(null);
const [imagePreviewEdit, setImagePreviewEdit] = useState(null);

const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState('');
const [serialStart, setSerialStart] = useState('');
const [error,setError]=useState('');
const[isLoading,setIsLoading]=useState(false);
const HandleSetProductId=(e)=>{
  e.preventDefault();
 setInputProduct(defaultProductState);
  
  
  setPackSalePrice('');
  setPackQty('1');
  setPackCostPrice('');
  setProductId('');
  setUnitRetailPrice('');
  
    }
const handleSearchChange = (event) => {

  setSearchQuery(event.target.value);
  setCurrentPage(1);
};

const handleImageChange = (e) => {
  const file = e.target.files[0];

  // Check if the file exists and its size is less than or equal to 20 KB
  if (file && file.size <= 20480) {
    setImage(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    reader.readAsDataURL(file);
  } else {
    toast.error('Image Size Should Be Less Than 20kb', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }
};



useEffect(()=>{
 
  axios.get(`/api/view/products?page=${currentPage}&search=${searchQuery}`).then(response=>{
  
    setItemsPerPage(response.data.products.per_page);
    setProducts(response.data.products.data);
    setTotalPages(response.data.products.last_page);
  })
 
  

},[currentPage,searchQuery,isThisClick,isClick]);


const handleSubmitImport=()=>{

}
const handleFileChange=()=>{

}

useEffect(() => {
  setSerialStart((currentPage - 1) * itemsPerPage + 1);
}, [currentPage, itemsPerPage]);


    const handlePackSalePrice=(e)=>{
setPackSalePrice(e.target.value);


    }
    const handlePackCostPrice=(e)=>{


      setPackCostPrice(e.target.value);
    }
    const handlePackSize=(e)=>{

setPackSize(e.target.value);

      
    }
    const handlePackQty=(e)=>{


      setPackQty(e.target.value);
    }
   
    const defaultProductState = {
      name: '',
      alias: '',
      weight: '0',
      color: '',
      unit: 'Pcs',
      hold: '1',
      alert: '',
      aisle: '',
      bin: '',
      unitwholesaleprice: '',
      errorlist: [],
    };

    const [inputProduct,setInputProduct]=useState(defaultProductState);

   useEffect(()=>{
const qty=(packqty*packsize).toFixed(2);
setQty(qty);
if(qty>0){
setUnitSalePrice((packsaleprice/packsize).toFixed(2));
setUnitCostPrice((packcostprice/packsize).toFixed(2));
}else{
setUnitSalePrice(0);
setUnitCostPrice(0);

}



   },[packsize,packqty,packsaleprice,packcostprice]) ;

  

      const handleInput=(e)=>{
          
  e.preventDefault();


  setInputProduct({...inputProduct,[e.target.name]:e.target.value});
      }

useEffect(()=>{
    axios.get(`/api/fetch/distributer`).then(response=>{
           
        setDistributer(response.data.user);
        setDefaultDistributer(response.data.user[0]['id']);
       
    
      })


},[]);

useEffect(()=>{
       
    axios.get(`/api/fetch/category`).then(response=>{
       
      setCategory(response.data.category);
      setDefaultCategory(response.data.category[0]['id']);
    
     
    })
    
    
 
 
  
  },[]);
useEffect(()=>{

    axios.get(`/api/fetch/store`).then(response=>{
           
        setStore(response.data.store);
        setDefaultStore(response.data.store[0]['id']);
       
        
      })

},[]);


 
    

const handleSubmit = (e) => {
  //setIsThisClick(true);
  setIsClick(true);
  e.preventDefault();

  const defaultData = {
    category_id: defaultCategory,
    distributer_id: defaultDistributer,
    store_id: defaultStore,
    pack_sale_price: packsaleprice,
    pack_cost_price: packcostprice,
    unitsaleprice: unitsaleprice,
    unitcostprice: unitcostprice,
    pack_size: packsize,
    pack_qty: packqty,
    qty: qty,
    unitretailprice:unitretailprice,
  };

  const formData = new FormData();
  formData.append('image', image);

  // Append other form data fields to the formData object
  for (const key in defaultData) {
    formData.append(key, defaultData[key]);
  }
  for (const key in inputProduct) {
    formData.append(key, inputProduct[key]);
  }

  axios
    .post('api/add/product', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      if (res.data.status === 200) {
        toast.success('Item Successfuly Added!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          setInputProduct(defaultProductState);
  
  
  setPackSalePrice('');
  setPackQty('1');
  setPackCostPrice('');
  setProductId('');
  setUnitRetailPrice('');
  
        //setIsThisClick(false);
        setIsClick(false);
      }else if(res.data.status===400){
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        //setIsThisClick(false);
        setIsClick(false);
      }
      
      
      else {
        setInputProduct({ ...inputProduct, errorlist: res.data.validation_errors });
        setIsClick(false);
      }
      setIsClick(false);

    });
};


const handleDelete=(e,id,index)=>{
setIsThisClick(index);
axios.delete(`api/delete/product/${id}`).then(res=>{
if(res.data.status===200){
  toast.success('Item Deleted Successfuly!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
setIsThisClick('');
}else{

}

})


}
const handleEdit=(e,id,index)=>{
  e.preventDefault();
setIsThisClick(index);
setProductId(id);
axios.get(`api/edit/product/${id}`).then(res=>{

  setDefaultCategory(res.data.product.category_id);
setDefaultStore(res.data.product.store_id);
setDefaultDistributer(res.data.product.distributer_id);
setPackQty(res.data.product.pack_qty);
setPackSize(res.data.product.pack_size);
setQty(res.data.product.qty);
setPackSalePrice(res.data.product.pack_sale_price);
setPackCostPrice(res.data.product.pack_cost_price);
setUnitCostPrice(res.data.product.unitcostprice);
setUnitCostPrice(res.data.product.unitcostprice);
setUnitRetailPrice(res.data.product.unitretailprice===null?'0':res.data.product.unitretailprice);
setImagePreview(null);

setImagePreviewEdit(res.data.product.image);
const updateitems={
...inputProduct,
'name':res.data.product.name===null?'':res.data.product.name,
'color':res.data.product.color===null?'':res.data.product.color,
'weight':res.data.product.weight===null?'':res.data.product.weight,
'unit':res.data.product.unit===null?'':res.data.product.unit,
'alert':res.data.product.productalert===null?'':res.data.product.productalert,
'hold':res.data.product.hold===null?'':res.data.product.hold,
'alias':res.data.product.alias===null?'':res.data.product.alias,
'aisle':res.data.product.class===null?'':res.data.product.class,
'bin':res.data.product.box===null?'':res.data.product.box,
'unitwholesaleprice':res.data.product.unitwholesaleprice===null?'0':res.data.product.unitwholesaleprice,
}


setInputProduct(updateitems);








setIsThisClick('');
//setInputProduct({...inputProduct,})


});



}

const handleUpdate=(e)=>{
  setIsClick(true);
e.preventDefault();

const defaultData={

  'category_id':defaultCategory,
  'distributer_id':defaultDistributer,
  'store_id':defaultStore,
  'pack_sale_price':packsaleprice,
  'pack_cost_price':packcostprice,
  'unitsaleprice':unitsaleprice,
  'unitcostprice':unitcostprice,
  'pack_size':packsize,
  'pack_qty':packqty,
  'qty':qty,
  'unitretailprice':unitretailprice,
  'productid':productid,
  
  
  
  }
  
  //const data={...inputProduct,...defaultData};
  const formData = new FormData();
  formData.append('image', image);

  // Append other form data fields to the formData object
  for (const key in defaultData) {
    formData.append(key, defaultData[key]);
  }
  for (const key in inputProduct) {
    formData.append(key, inputProduct[key]);
  }
  axios.post('api/update/product', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(res=>{
if(res.data.status===401){
setInputProduct({...inputProduct,errorlist:res.data.validation_errors});
}
else if (res.data.status===400){

  
    toast.error(res.data.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  setImagePreviewEdit(null);
  
}
else{
  toast.success('Item Updated Saved!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
setImagePreviewEdit(null);
}
setIsClick(false);


  })


}
const handleExport=()=>{
 setIsClick(true);
  axios({
    url: 'api/products/export',
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'products.xlsx'); // or any other filename you prefer
    document.body.appendChild(link);
    link.click();
setIsClick(false);

  });



}

const handleExportPdf=()=>{
 setIsClick(true);

  axios({
    url: 'api/products/export/pdf',
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    window.open(url, '_blank');
    
   
   setIsClick(false);
  });



}

  return (
    <div className='animated fadeInDown'>
      {productid?
      <center>
    <form encType="multipart/form-data" onSubmit={handleUpdate} method='post'>

        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>Edit Product</h2><hr></hr>
            </div>   
            <Row className="g-2">
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Name" >
        <Form.Control type="text"  name="name" placeholder="Product Name" value={inputProduct.name} onChange={(e)=>handleInput(e)} />
        <span style={{color:"red"}}>{inputProduct.errorlist.name}</span>
      </FloatingLabel>
      
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Color" >
        <Form.Control type="text"  name="color" placeholder="Product color" value={inputProduct.color} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
      
     
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product weight" >
        <Form.Control type="number" name="weight" placeholder="Product weight" value={inputProduct.weight} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div>
    </Row>
   
    <Row className="g-2">
    <div className='col-md mt-4'>
    <FloatingLabel  label="Qty" >
        <Form.Control type="number"  name="pack_qty" placeholder='pack qty'  value={packqty} onChange={(e)=>handlePackQty(e)} />
      </FloatingLabel>
     
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Qty Alert" >
        <Form.Control type="number"  name="alert" placeholder='alert' value={inputProduct.alert} onChange={handleInput} />
      </FloatingLabel>
     
    </div>
    <div className='col-md mt-4' style={{display:'none'}}>
    <FloatingLabel  label="Number of each" >
        <Form.Control type="number"  name="pack_size" placeholder='pack size' value={packsize} onChange={(e)=>handlePackSize(e)} />
      </FloatingLabel>
     
    </div>

    <div className='col-md mt-4' style={{display:'none'}}>
    <FloatingLabel  label="Total Qty" >
        <Form.Control type="number"  name="qty" disabled placeholder='qty' value={qty}  />
      </FloatingLabel>
     
    </div></Row>
    <Row className="g-2">

    <div className='col-md mt-4'>
    <FloatingLabel  label="Unit Retail Price" >
        <Form.Control type="number"  name="pack_sale_price" placeholder='pack sale price'  value={packsaleprice} onChange={(e)=>handlePackSalePrice(e)} />
      </FloatingLabel>
      <span style={{color:"red"}}>{inputProduct.errorlist.pack_sale_price}</span>
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Unit Cost Price" >
        <Form.Control type="number"  name="pack_cost_price" placeholder='pack cost price'  value={packcostprice} onChange={(e)=>handlePackCostPrice(e)} />
      </FloatingLabel>
      <span style={{color:"red"}}>{inputProduct.errorlist.pack_cost_price}</span>
    </div>
    <div className='col-md mt-4' style={{display:'none'}}>
    <FloatingLabel  label="Unit Sale Price" >
        <Form.Control type="number" disabled  name="unitsaleprice" placeholder='unitsaleprice' value={unitsaleprice} />
      </FloatingLabel>
   
    </div></Row>
    <Row className="g-2">

   
    <div className='col-md mt-4' style={{display:'none'}}>
    <FloatingLabel  label="Unit Cost Price" >
        <Form.Control type="number" disabled  name="unitcostprice" placeholder='unitcostprice' value={unitcostprice} />
      </FloatingLabel>
     
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Unit Sale Price" >
        <Form.Control type="number"  value={unitretailprice}  name="unitretailprice" placeholder='unitretailprice' onChange={(e)=>setUnitRetailPrice(e.target.value)} />
      </FloatingLabel>
     
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Unit WholeSale Price" >
        <Form.Control type="number"  value={inputProduct.unitwholesaleprice}  name="unitwholesaleprice" placeholder='unitwholesaleprice' onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div>
    </Row>
    <Row className="g-2">

  
    
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Unit">
      <Form.Select aria-label="Product Unit"  value={inputProduct.unit} name='unit' onChange={(e)=>handleInput(e)}>
        <option value="Box">Box</option>
     
        <option value="Pcs">Pcs</option>
        
        
      </Form.Select>
    </FloatingLabel>
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Category">
      <Form.Select aria-label="Product Category" value={defaultCategory} name='category_id' onChange={(e)=>setDefaultCategory(e.target.value)}>
      {category && category.map((item ,index)=> (
  <option key={item.id} value={item.id}>
{item.name}
    
  </option>
))}
       
      </Form.Select>
    </FloatingLabel>
   
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Distributer Name">
   
      <Form.Select aria-label="Distributer Name"  value={defaultDistributer}  name='distributer_id' onChange={(e)=>setDefaultDistributer(e.target.value)}>
       
      {distributer && distributer.map(item => (
        
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>
    </Row>
   


 
   
<Row className='g-1'>
<div className='col-md mt-4'>
      <input className='form-control'name='image' type="file" accept="image/*" onChange={handleImageChange} />
    </div>
    <div className='col-md mt-4'>
     
      {imagePreviewEdit && <img src={`${weburl}${imagePreviewEdit}`} alt="Preview" width="50" height="50" />}
    {imagePreview && <img alt='uploaded' src={imagePreview} width="50" height="50" ></img>}
    </div>

</Row>
   
    <Row className='g-2'>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Store Name">
      <Form.Select aria-label="Store Name" value={defaultStore} name='store_id' onChange={(e)=>setDefaultStore(e.target.value)}>
       
      {store && store.map(item => (
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div> 
<div className='col-md mt-4'>
    <FloatingLabel  label="Product Aisle" >
        <Form.Control type="text"  name="aisle" placeholder="Product Aisle" value={inputProduct.aisle} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Bin" >
        <Form.Control type="text"  name="bin" placeholder="Product Bin" value={inputProduct.bin} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div>
</Row>
    <Row className="g-2">

    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Visibility">
      <Form.Select aria-label="Product Visibility"  value={inputProduct.hold} name='hold' onChange={(e)=>handleInput(e)}>
       
        <option value="1">Yes</option>
        <option value="2">No</option>
       
      </Form.Select>
    </FloatingLabel>
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Barcode" >
        <Form.Control type="text"  name="alias" placeholder="Product Alias" value={inputProduct.alias} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div></Row>
    

    <Row className='g-2'>
    <div className='col-md mt-4'>
<button  className='btn btn-success btn-lg btn-block'  > {isClick? <Puff  strokeOpacity={.25}/>:'Update'}  </button>

    </div>
    <div className='col-md mt-4'>
<button  className='btn btn-danger btn-lg btn-block' onClick={(e)=>HandleSetProductId(e)}>Add New  </button>

    </div>
    </Row>
    </div></form></center>:  <center>
       
    <form encType="multipart/form-data" onSubmit={handleSubmit} method='post'>
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>Add Product</h2><hr></hr>
            </div>   
            <Row className="g-2">
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Name" >
        <Form.Control type="text"  name="name" placeholder="Product Name" value={inputProduct.name} onChange={(e)=>handleInput(e)} />
        <span style={{color:"red"}}>{inputProduct.errorlist.name}</span>
      </FloatingLabel>
      
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Color" >
        <Form.Control type="text"  name="color" placeholder="Product color" value={inputProduct.color} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
      
     
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product weight" >
        <Form.Control type="number" name="weight" placeholder="Product weight" value={inputProduct.weight} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div>
    
    </Row>
    <Row className="g-2">
    
  
    <div className='col-md mt-4'>
    <FloatingLabel  label="Qty" >
        <Form.Control type="number"  name="pack_qty" placeholder='pack qty'  value={packqty} onChange={(e)=>handlePackQty(e)} />
      </FloatingLabel>
     
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Qty Alert " >
        <Form.Control type="number"  name="alert" placeholder='alert' value={inputProduct.alert} onChange={handleInput} />
      </FloatingLabel>
     
    </div>
    <div className='col-md mt-4' style={{display:'none'}}>
    <FloatingLabel  label="Number of each" >
        <Form.Control type="number"  name="pack_size" placeholder='pack size' value={packsize} onChange={(e)=>handlePackSize(e)} />
      </FloatingLabel>
     
    </div>

    <div className='col-md mt-4' style={{display:'none'}}>
    <FloatingLabel  label="Total Qty" >
        <Form.Control type="number"  name="qty" disabled placeholder='qty' value={qty}  />
      </FloatingLabel>
     
    </div></Row>
    <Row className="g-2">

    <div className='col-md mt-4'>
    <FloatingLabel  label="Unit Retail Price" >
        <Form.Control type="number"  name="pack_sale_price" placeholder='pack sale price'  value={packsaleprice} onChange={(e)=>handlePackSalePrice(e)} />
      </FloatingLabel>
      <span style={{color:"red"}}>{inputProduct.errorlist.pack_sale_price}</span>
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Unit Cost Price" >
        <Form.Control type="number"  name="pack_cost_price" placeholder='pack cost price'  value={packcostprice} onChange={(e)=>handlePackCostPrice(e)} />
      </FloatingLabel>
      <span style={{color:"red"}}>{inputProduct.errorlist.pack_cost_price}</span>
    </div> <div className='col-md mt-4' style={{display:'none'}}>
    <FloatingLabel  label="Unit Sale Price" >
        <Form.Control type="number" disabled  name="unitsaleprice" placeholder='unitsaleprice' value={unitsaleprice} />
      </FloatingLabel>
   
    </div></Row>
    <Row className="g-2">

   
    <div className='col-md mt-4' style={{display:'none'}}>
    <FloatingLabel  label="Unit Cost Price" >
        <Form.Control type="number" disabled  name="unitcostprice" placeholder='unitcostprice' value={unitcostprice} />
      </FloatingLabel>
     
    </div>

    <div className='col-md mt-4'>
    <FloatingLabel  label="Unit Sale Price" >
        <Form.Control type="number"  value={unitretailprice}  name="unitretailprice" placeholder='unitretailprice' onChange={(e)=>setUnitRetailPrice(e.target.value)} />

  <span style={{color:"red"}}>{inputProduct.errorlist.unitretailprice}</span>
      
      </FloatingLabel>
     
    </div>
   
  
    <div className='col-md mt-4'>
<FloatingLabel  label="Unit WholeSale Price" >
    <Form.Control type="text"  name="unitwholesaleprice" placeholder="Product WholeSalePrice" value={inputProduct.unitwholesaleprice} onChange={(e)=>handleInput(e)} />
  </FloatingLabel>
  <span style={{color:"red"}}>{inputProduct.errorlist.unitwholesaleprice}</span>
 
</div>
    </Row>

    <Row className="g-2">
  
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Unit">
      <Form.Select aria-label="Product Unit" name='unit'  value={inputProduct.unit} onChange={(e)=>handleInput(e)}>
        <option value="Box">Box</option>
   
        <option value="Pcs">Pcs</option>
       
      </Form.Select>
    </FloatingLabel>
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Category">
      <Form.Select aria-label="Product Category" name='category_id' onChange={(e)=>setDefaultCategory(e.target.value)}>
      {category && category.map(item => (
  <option key={item.id} value={item.id}>
    {item.name}
  </option>
))}
       
      </Form.Select>
    </FloatingLabel>
    {inputProduct.distributer_id}
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Distributer Name">
   
      <Form.Select aria-label="Distributer Name"   name='distributer_id' onChange={(e)=>setDefaultDistributer(e.target.value)}>
       
      {distributer && distributer.map(item => (
        
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div></Row>
    <Row className="g-2">

   

    <div className='col-md mt-4'>
    <FloatingLabel  label="Store Name">
      <Form.Select aria-label="Store Name" name='store_id' onChange={(e)=>setDefaultStore(e.target.value)}>
       
      {store && store.map(item => (
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>

<div className='col-md mt-4'>
    <FloatingLabel  label="Product Aisle" >
        <Form.Control type="text"  name="aisle" placeholder="Product Aisle" value={inputProduct.aisle} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Product Bin" >
        <Form.Control type="text"  name="bin" placeholder="Product Bin" value={inputProduct.bin} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div>
</Row>
<Row className='g-1'>
<div className='col-md mt-4'>
      <input className='form-control'name='image' type="file" accept="image/*" onChange={handleImageChange} />
    </div>
    <div className='col-md mt-4'>
      {imagePreview && <img src={imagePreview} alt="Preview" width="50" height="50" />}
    </div>

</Row>
<Row className="g-2">

<div className='col-md mt-4'>
<FloatingLabel  label="Product Visibility">
  <Form.Select aria-label="Product Visibility"  value={inputProduct.hold} name='hold' onChange={(e)=>handleInput(e)}>
   
    <option value="1">Yes</option>
    <option value="2">No</option>
   
  </Form.Select>
</FloatingLabel>
</div>

<div className='col-md mt-4'>
<FloatingLabel  label="Product Barcode" >
    <Form.Control type="text"  name="alias" placeholder="Product Barcode" value={inputProduct.alias} onChange={(e)=>handleInput(e)} />
  </FloatingLabel>
  <span style={{color:"red"}}>{inputProduct.errorlist.alias}</span>
 
</div>


</Row>
    <div className='col-md mt-4'>
<button type='sumbit' className='btn btn-success btn-lg btn-block' >{isClick? <Puff height={23} stroke='yellow' strokeOpacity={1.2}/>:'Save'}  </button>

    </div>
    </div>
    
    </form>
    </center>}

<Row className='g-1'>

<div className='col-md' style={{ height: 'auto', overflow: 'auto',border:'1px solid lightgray  ',padding:'15px'}}>

<Row className='g-2'>
  <div className='col-md-4 mb-1'>
  <input type="text" className='form-control col-md' placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />

  </div>

  <div className='col-md-3'>
  <button onClick={handleExport} className='btn  btn-warning ml-1'>{isClick?<Puff height={10}/>:'Excel'} </button>
  <button onClick={handleExportPdf} className='btn  btn-success ml-1'>{isClick?<Puff height={10}/>:'PDF'}  </button>

  </div>

  <div className='col-md-4'>

<form onSubmit={handleSubmitImport}>
           <input  type="file" disabled onChange={handleFileChange} />
           <button  type="submit" disabled  className='btn btn-sm btn-primary'>{isLoading?'Please Wait...':'Import Products'}</button>
          
           {error && (

<h6 style={{color:'green'}}>{error}</h6>
)}
       </form>

</div>

  
    
</Row>

    
    
    <Table striped bordered hover size="sm" >
    <thead>
        <tr>
        <th>Sr</th>
        <th>Name</th>
        <th>Image</th>
        <th>Barcode</th>
        <th>Aisle</th>
        <th>Bin</th>
        <th>Visibility</th>
        <th>Color</th>
        <th>Unit</th>
        <th style={{display:'none'}}>Qty</th>
        <th>Qty</th>
        <th>Retail Price</th>
        <th>Cost Price</th>
        <th>Category</th>
        <th>Store</th>
        <th>Distributer</th>
        <th>Delete</th>
            <th>Update</th>
           
        </tr>
    </thead>
  <tbody>
 {products && products.map((item,index)=>(


 <tr key={item.id}  style={{backgroundColor:item.qty<item.alert?'lightpink':item.hold==='2'?'red':'white'}}
 >

    <td>{serialStart+index}</td>
    <td >{item.name.length > 10 ? `${item.name.slice(0, 10)}...` : item.name}</td>
    <td ><img width={50} height={50} alt='sorry' src={`${weburl}${item.image}`}></img></td>
    <td >{item.alias}</td>
    <td >{item.class}</td>
    <td >{item.box}</td>
    <td >{item.hold==='1'?'visible':'hidden'}</td>
    <td >{item.color}</td>
 
    <td >{item.unit}</td>
    <td style={{display:'none'}}>{item.pack_qty}</td>
    <td >{item.qty}</td>

    <td >{item.unitsaleprice}</td>
    <td >{item.unitcostprice}</td>
    <td >{item.categoryname}</td>
    <td >{item.storename}</td>
    <td >{item.distributername}</td>
<td><button className='btn btn-danger' disabled={type!=='Managment'?true:false} onClick={(e) => handleDelete(e,item.id,index)} style={{marginRight:'3px'}}>{isThisClick===index?<Puff height={10} width={15}/> :<FaTrash style={{marginBottom:'3px'}}/>}</button> </td>
        
        <td>
        <button onClick={(e)=>handleEdit(e,item.id,index)} className='btn btn-success '> {isThisClick===index?<Puff height={10} width={15}/>: <FaEdit style={{marginBottom:'3px'}}/>}</button> 
        </td>
      <td> 


      </td>
       </tr>
      
 ))
    
}</tbody>  

</Table>
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>
</div>

</Row>

    </div>
  )
}

export default Product
